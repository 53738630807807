import AuthWrapper from "components/shared/wrapper/authWrapper";

const Dashboard = () => {
  return (
    <>
      <AuthWrapper className="flex-col justify-between">
        <div className="container">
          <div className="relative flex flex-col items-center text-center gap-7 max-w-3xl mx-auto">
            <h1 className="text-[64px] leading-1.2 font-semibold text-grayNew">
              Sync your client data to HubSpot fast
            </h1>
            <p className=" text-2xl font-bold text-grayNew">
              Welcome to the iSolved &lt; &gt; HubSpot smart tool
            </p>
          </div>
          <img
            src="/images/FlyingTriangles.gif"
            className="w-full h-auto"
            alt="Flying Triangles"
          />
        </div>
        <footer className="mt-12 text-center">
          <p className=" text-sm text-grey2">© The Gist 2023</p>
        </footer>
      </AuthWrapper>

      {/* <Wrapper className="pb-28">
        <div className="sticky top-[116px] z-10 border-b border-dark bg-white">
          <div className="flex items-center gap-4 container !max-w-[1360px] h-16">
            {menuList.map((items, i) => (
              <button
                key={i}
                type="button"
                onClick={() => setIsActive(items.id)}
                className={`relative text-lg h-full font-bold border-y-2 border-y-transparent transition-all ${
                  isActive === items.id
                    ? "text-ocean border-b-ocean"
                    : "text-dark hover:text-ocean"
                } `}
              >
                {items.title}
              </button>
            ))}
          </div>
        </div>
        {isActive === 1 && (
          <>
            <div className="relative py-10">
              <div className="container">
                <h2 className="text-[32px] font-light mb-10">
                  Company Properties
                </h2>

                <div className="grid grid-cols-2 mb-11 gap-4">
                  <div className="text-base font-bold text-grey2">
                    iSolved Properties
                  </div>
                  <div className="text-base font-bold text-grey2">
                    HubSpot Properties
                  </div>
                </div>

                <div className="flex flex-col gap-y-11">
                  {[1, 2, 3, 4, 5].map((i) => (
                    <div className="grid grid-cols-12 gap-4" key={i}>
                      <div className="col-span-4 text-base font-bold text-grey2">
                        <Select />
                      </div>
                      <div className="col-span-2 text-xl font-bold text-grey2 flex items-center justify-center">
                        <BiRightArrowAlt />
                      </div>
                      <div className="col-span-4 text-base font-bold text-grey2">
                        <Select />
                      </div>
                      <div className="col-span-2 flex items-end justify-center">
                        <button
                          type="button"
                          onClick={() => setIsSave(!isSave)}
                          className=" group relative text-base text-red-500  flex items-center gap-2 pb-1.5"
                        >
                          <RiDeleteBinLine />
                          Remove
                          <span className="absolute group-hover:scale-x-100 bottom-0 left-0 h-[1px] w-full bg-red-500 block scale-x-0 transition-all duration-500 origin-left "></span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="fixed bottom-0 left-0 w-full px-10 h-28 flex items-center justify-between gap-40 bg-white z-40 shadow-active-head">
              <Button size="large" variant="darkOutline" className="z-[1]">
                Reset Properties
              </Button>

              <div className="relative flex items-center gap-10">
                <Button
                  size="large"
                  name="Save Progress"
                  variant="oceanFill"
                  className={` absolute whitespace-nowrap transition-all duration-500 ${
                    isSave
                      ? "right-[200px] opacity-100 pointer-events-auto"
                      : "opacity-0 -right-0 pointer-events-none"
                  }`}
                />
                <Button size="large" variant="darkFill">
                  + Add Property
                </Button>
              </div>
            </div>
          </>
        )}
      </Wrapper> */}
    </>
  );
};

export default Dashboard;

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShowToastMessage = ({
  title,
  direction,
  type,
}: {
  title: string;
  direction?: string;
  type?: string;
}) => {
  if (type === "success") {
    toast.success(title, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error(title, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export default ShowToastMessage;

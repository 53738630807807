import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getAuth } from "redux/slices/authSlice";

interface Props {
  children: React.JSX.Element;
}

const RequiresAuth = ({ children }: Props, key: any) => {
  const location = useLocation();

  const { isAuthenticated, isSuperAdmin } = useSelector(getAuth);

  /* Not Logged In */
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // if (isSuperAdmin) {
  //   if (
  //     location.pathname === "/dashboard" ||
  //     location.pathname === "/portals" ||
  //     location.pathname.includes("/log")
  //   ) {
  //     return children;
  //   } else {
  //     return <Navigate to="/login" />;
  //   }
  // }

  return children;
};

export default RequiresAuth;

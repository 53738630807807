// ** post **

import { AxiosRequestConfig } from "axios";
import { log } from "console";
import { useAxiosGet, useAxiosPost } from "hooks/useAxios";

const AUTH_API_BASE_PATH = "/authorize";

//  ** Get Logged User Details **
export const useGetLoggedInUserAPI = () => {
  // ** custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();

  const getLoggedInUserAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/get-user`, data, config);
  };

  return { getLoggedInUserAPI, isLoading, isError, isSuccess };
};

// ** post **
//  ** Register User **
export const useRegisterUserApi = () => {
  // ** custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();

  const registerUserApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/register`, data, config);
  };

  return { registerUserApi, isLoading, isError, isSuccess };
};

// ** post **
//  ** Login User **
export const useLoginPostAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();

  const loginPostAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return await callApi(`${AUTH_API_BASE_PATH}/login`, data, config);
  };

  return { loginPostAPI, isLoading, isError, isSuccess };
};

// ** get **
// ** hubspot authentication **

export const useHubspotAuthGetAPI = () => {
  const [callApi, { isLoading, isSuccess, isError }] = useAxiosGet();
  const hubspotGetAPI = async (config: AxiosRequestConfig<object> = {}) => {
    return await callApi(`${AUTH_API_BASE_PATH}/hubspot`, config);
  };

  return { hubspotGetAPI, isError, isSuccess, isLoading };
};

// ** post **
// ** forgot passoword **

export const useForgotPasswordPostAPI = () => {
  const [callApi, { isError, isLoading, isSuccess }] = useAxiosPost();

  const forgotPasswordAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/forgot-password`, data, config);
  };

  return { forgotPasswordAPI, isError, isLoading, isSuccess };
};

// ** post **
// ** forgot password **

export const useResetPasswordPostAPI = () => {
  const [callApi, { isError, isLoading, isSuccess }] = useAxiosPost();

  const resetPasswordAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/reset-password`, data, config);
  };

  return { resetPasswordAPI, isError, isLoading, isSuccess };
};

import React, { useState, useEffect } from "react";
import Button from "components/common/button";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useHubspotAuthGetAPI } from "pages/auth/services/auth.service";
import { REACT_APP_API_URL } from "configs/env";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, setLogoutData } from "redux/slices/authSlice";
import ShowToastMessage from "components/common/toast/toast";

const AuthHeader = ({ withWhiteBg = false }: { withWhiteBg?: boolean }) => {
  // *******custom hook ****************
  const { isAuthenticated } = useSelector(getAuth);

  const { hubspotGetAPI, isLoading: loader } = useHubspotAuthGetAPI();

  const [scrollHead, setScrollHead] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        setScrollHead(true);
      } else {
        setScrollHead(false);
      }
    });
  }, []);

  const hubspotAuthorization = async () => {
    const { data } = await hubspotGetAPI();

    if (!data) {
      ShowToastMessage({
        title: "Something went wrong can't auth the portal!",
        type: "error",
      });
      return;
    }
    if (typeof window == "object") {
      window.location.href = data;
    }
  };

  return (
    <header
      className={`fixed top-0 left-0 w-full bg-white shadow-active-head ${
        withWhiteBg && "bg-white"
      } z-[999999] ${
        scrollHead ? "py-2.5 " : "py-7"
      } transition-all duration-200 `}
    >
      <div className="container">
        <div className="flex items-center justify-between gap-10">
          <Link to="/" className="flex items-center">
            <img
              src="/images/logo.png"
              width={201}
              height={30}
              alt="gist logo black"
            />
          </Link>
          <div className="relative flex items-center gap-4">
            {isAuthenticated && location.pathname !== "/" ? (
              <Button
                variant="blank"
                className="mr-4"
                onClick={() => navigate("/")}
              >
                <HiOutlineArrowLeft />
                Back to Home
              </Button>
            ) : (
              ""
            )}
            {isAuthenticated ? (
              <Button
                type="button"
                variant="darkOutline"
                onClick={() => {
                  navigate("/properties");
                }}
                // isLoading={loader}
              >
                Properties
              </Button>
            ) : (
              <Button
                type="button"
                variant="darkOutline"
                onClick={hubspotAuthorization}
                isLoading={loader}
              >
                Install App
              </Button>
            )}
            {isAuthenticated ? (
              <Button
                type="button"
                variant="darkFill"
                // isLoading={loader}
                onClick={() => {
                  dispatch(setLogoutData());
                  ShowToastMessage({
                    title: "Portal Logout  Successfully!",
                    type: "success",
                  });
                }}
              >
                Logout
              </Button>
            ) : (
              <Button
                // className="w-"
                type="button"
                variant="darkFill"
                // isLoading={loader}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default AuthHeader;

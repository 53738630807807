import { combineReducers } from "@reduxjs/toolkit";
import {
  reducer as authReducer,
  reducer as toastReducer,
} from "./slices/authSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  toast: toastReducer,
});

export default rootReducer;

import SocketComponent from "components/common/socket";
// import Properties from "pages/properties";
import React from "react";

export interface Route {
  key: string; // must be unique for each route
  path: string;
  title?: string;
  component: React.JSX.Element;
}

// auth
const Login = React.lazy(() => import("pages/auth/login"));
const ForgotPassword = React.lazy(() => import("pages/auth/forgot-password"));
const Register = React.lazy(() => import("pages/auth/register"));
const ResetPassword = React.lazy(() => import("pages/auth/reset-password"));
const EmailPage = React.lazy(() => import("pages/emailPage"));

// home
const Dashboard = React.lazy(() => import("pages/dashboard"));
const Properties = React.lazy(() => import("pages/properties"));

// Admin
const Portals = React.lazy(() => import("pages/admin/portal"));
const Logs = React.lazy(() => import("pages/admin/log"));
const AdminDashboard = React.lazy(() => import("pages/admin/dashboard"));

/** PRIVATE ROUTES */
export const privateRoutes: Array<Route> = [
  {
    key: "socket_route",
    path: "/socket",
    title: "Socket",
    component: <SocketComponent />,
  },
  {
    key: "home_route",
    path: "/",
    title: "Dashboard",
    component: <Dashboard />,
  },

  {
    key: "properties_route",
    path: "/properties",
    title: "Properties",
    component: <Properties />,
  },
  {
    key: "admin_route",
    path: "/portals",
    title: "Portals",
    component: <Portals />,
  },

  {
    key: "admin_route",
    path: "/dashboard",
    title: "Admin dashboard",
    component: <AdminDashboard />,
  },

  {
    key: "admin_route",
    path: "/logs",
    title: "Migration log",
    component: <Logs />,
  },
  {
    key: "admin_route",
    path: "/logs/:id",
    title: "data log",
    component: <Logs />,
  },
];

/** PUBLIC ROUTES */
export const publicRoutes: Array<Route> = [
  { key: "login_route", path: "/login", title: "Login", component: <Login /> },
  {
    key: "register_route",
    path: "/register/:portalId",
    title: "Register",
    component: <Register />,
  },
  {
    key: "forgot_password_route",
    path: "/forgot-password",
    title: "Forgot-password",
    component: <ForgotPassword />,
  },
  {
    key: "verify",
    path: "/verify-account/:token",
    title: "verify-account",
    component: <EmailPage />,
  },
  {
    key: "reset_password_route",
    path: "/reset-password/:resetToken",
    title: "Reset Password",
    component: <ResetPassword />,
  },
  // {
  //   key: "hubspot_authorization_route",
  //   path: "/hubspot",
  //   title: "Hubspot-Auth",
  //   component: "",
  // },
];

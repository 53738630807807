import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getAuth, getCurrentPortal } from "redux/slices/authSlice";

interface Props {
  children: React.JSX.Element;
}

const RequiresUnAuth = ({ children }: Props) => {
  const { isAuthenticated, isSuperAdmin } = useSelector(getAuth);

  const portal = useSelector(getCurrentPortal);

  if (isAuthenticated && isSuperAdmin) {
    return <Navigate to="/dashboard" />;
  }

  /* Not Logged In */
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default RequiresUnAuth;

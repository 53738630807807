import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import RequiresUnAuth from "../pages/auth/guard/RequireUnAuth";
import RequiresAuth from "../pages/auth/guard/RequiresAuth";
import SiteLoader from "../components/common/loader/SiteLoader";
import { ToastContainer } from "react-toastify";
import Dashboard from "pages/dashboard";

const NotFound = React.lazy(() => import("pages/not-found"));

// lazy loading

function CustomRouter() {
  return (
    <>
      <ToastContainer autoClose={2000} />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<SiteLoader variant="gradient" />}>
              <Dashboard />
            </Suspense>
          }
        />
        {publicRoutes &&
          publicRoutes.length > 0 &&
          publicRoutes.map((route, idx) => {
            return route.component ? (
              <Route
                key={route.key}
                path={route.path}
                element={
                  <RequiresUnAuth>
                    <Suspense fallback={<SiteLoader variant="gradient" />}>
                      {route.component}
                    </Suspense>
                  </RequiresUnAuth>
                }
              />
            ) : null;
          })}
        {privateRoutes?.length > 0 &&
          privateRoutes.map((route, idx: number) => {
            return route.component ? (
              <Route
                key={route.key}
                path={route.path}
                element={
                  <RequiresAuth>
                    <Suspense fallback={<SiteLoader variant="gradient" />}>
                      {route.component}
                    </Suspense>
                  </RequiresAuth>
                }
              />
            ) : null;
          })}

        <Route
          path="*"
          element={
            <Suspense fallback={<SiteLoader variant="gradient" />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default CustomRouter;

import React, { ReactNode } from "react";
import AuthHeader from "../header/authHeader";

const AuthWrapper = ({
  children,
  className,
  showHeader = true,
}: {
  children?: ReactNode;
  className?: string;
  showHeader?: any;
}) => {
  return (
    <main
      className={`relative flex flex-col ${
        showHeader && "pt-[116px]"
      }  min-h-screen bg-primary overflow-hidden`}
    >
      {showHeader && <AuthHeader />}
      <div className={`relative py-10 flex-1 flex items-center ${className} `}>
        {children}
      </div>
    </main>
  );
};

export default AuthWrapper;

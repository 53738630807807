import React, { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { REACT_APP_API_URL } from "../../../configs/env";

const SocketComponent = () => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = io(`${REACT_APP_API_URL}`, {
      autoConnect: false,
    });
    setSocket(newSocket);

    newSocket.connect();

    newSocket.emit("joinPortal", 12);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  return (
    <div>
      <input type="text" placeholder="Enter a message" />
      <button
        onClick={() => {
          console.log("socket check");
        }}
      >
        Send
      </button>
    </div>
  );
};

export default SocketComponent;
